<template>
  <div class="page">
    <el-container style="height: 100vh">
      <el-aside width="200px" class="sidebar el-scrollbar">
        <!-- 刷新选中 -->
        <!-- background-color="#545c64" -->
        <el-menu
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
          background-color="#304156"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
            <!-- index用于获取当前路由位置用 -->
            <!-- 暂时隐藏 -->
          <!-- <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-location"></i><span>文章首页</span>
            </template>
            <router-link :to="{ path: '/article/CreateArticle' }" class="style-a">
          
              <el-menu-item index="/article/CreateArticle"> 创建文章 </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/article/ListArt' }" class="style-a">
              <el-menu-item index="/article/ListArt"> 文章列表 </el-menu-item>
            </router-link>
          </el-submenu> -->

            <!-- 暂时隐藏 -->
          <!-- <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>任务中心</span>
            </template> -->

            <!-- <router-link :to="{ path: '/home/testTable' }" class="style-a">
              <el-menu-item index="/home/testTable">
                  千帆大模型AI
                </el-menu-item>
            </router-link>
            <router-link :to="{ path: '/tasks/queuedTask' }" class="style-a">
              <el-menu-item index="/tasks/queuedTask"> 欧派订单任务排序 </el-menu-item>
            </router-link> -->
            <!-- <router-link :to="{ path: '/tasks/CustomerVoucher' }" class="style-a">
              <el-menu-item index="/tasks/CustomerVoucher"> 图表可视化 </el-menu-item>
            </router-link> -->


          <!-- </el-submenu> -->

          
          <el-submenu index="3">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>顾客模块</span>
            </template>
            <!-- <router-link :to="{ path: '/test/RegList' }" class="style-a">
              <el-menu-item index="/test/RegList"> 拥军活动礼品领取 </el-menu-item>
            </router-link> -->
            <!-- 暂时隐藏 -->
            <!-- <router-link :to="{ path: '/test/RegListSaiHan' }" class="style-a">
              <el-menu-item index="/test/RegListSaiHan"> 活力赛罕·惠企利民 </el-menu-item>
            </router-link> -->

            <!-- 第二批房展会活动 -->
            <router-link :to="{ path: '/test/RegFangZhanSecond' }" class="style-a">
              <el-menu-item index="/test/RegFangZhanSecond"> 线上房展会核销 </el-menu-item>
            </router-link>  
            <router-link  v-if="isAdminOrRoot" :to="{ path: '/test/RegFangZhanSecondAll' }" class="style-a">
              <el-menu-item index="/test/RegFangZhanSecondAll"> 房展会核销状态 </el-menu-item>
            </router-link>

            <!-- 第一批房展会活动 已结束 暂时注释 -->
            <!-- <router-link :to="{ path: '/test/RegListFangZhan' }" class="style-a">
              <el-menu-item index="/test/RegListFangZhan"> 线上房展会核销 </el-menu-item>
            </router-link>  
            <router-link  v-if="isAdminOrRoot" :to="{ path: '/test/RegListFangZhanAll' }" class="style-a">
              <el-menu-item index="/test/RegListFangZhanAll"> 房展会核销状态 </el-menu-item>
            </router-link> -->


            <!-- 活动已结束 暂时隐藏 -->
            <!-- <router-link v-if="isAdminOrUser" :to="{ path: '/test/RegListTeach' }" class="style-a">
              <el-menu-item index="/test/RegListTeach"> 教师节洗衣机核销 </el-menu-item>
            </router-link> -->
            
            <!-- 暂时隐藏 -->
            <!-- <router-link :to="{ path: '/test/getWebSite' }" class="style-a">
              <el-menu-item index="/test/getWebSite"> 商场角色管理 </el-menu-item>
            </router-link> -->

            <!-- <router-link :to="{ path: '/test/CustomerList' }" class="style-a">
              <el-menu-item index="/test/CustomerList"> 全部客户 </el-menu-item>
            </router-link> -->
          </el-submenu>
          
        </el-menu>
      </el-aside>

      <!-- 内容区域 -->
      <el-container>
        <!-- header -->
        <el-header style="text-align: right">
          <!-- <el-dropdown>
            <i class="el-icon-setting" style="margin-right: 15px"></i>
            <el-dropdown-menu slot="dropdown">  
              <el-dropdown-item>用户信息</el-dropdown-item>
              <el-dropdown-item>修改密码</el-dropdown-item>
              <el-dropdown-item>返回登录页</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>admin</span> -->
          <div class="navbar">
            <div class="navbar-left">
              <el-button
                icon="el-icon-full-screen"
                @click="toggleFullScreen"
              ></el-button>
            </div>
            <div class="navbar-right">
              <el-dropdown trigger="click">
                <span class="avatar-wrapper">
                  <img :src="avatarUrl" alt="Avatar" />
                </span>
                <!-- 菜单 -->
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click="showProfile"
                    >个人中心</el-dropdown-item
                  >
                  <!-- <el-dropdown-item @click="logout">退出登录</el-dropdown-item> -->
                  <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <!-- 路由部分 -->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false, // 是否折叠侧边栏
      activeIndex: "", // 初始化 activeIndex
      isFullScreen: false,
      avatarUrl: require("@/assets/touxiang.png"), // 替换为你的头像路径
      isAdminOrDar: false, // 新增属性
      isAdminOrRoot: false, // 新增属性
      isAdminOrUser: false, // 教师节活动新增属性
    };
  },
  methods: {
    toggleFullScreen() {  
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        // 进入全屏模式
        document.documentElement.requestFullscreen();
      } else {
        // 退出全屏模式
        document.exitFullscreen();
      }
    },
    showProfile() {
      // 展示个人中心相关操作
    },

    // 退登
    logout() {
      this.$confirm("确认退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // localStorage.clear();
        localStorage.removeItem('user');
        this.$router.push('/');
      }).catch(() =>{
        console.log("取消")
      })
    },

    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  created() {
    // localStorage读取当前选中的侧边栏菜单值 activeIndex
    let index = localStorage.getItem("activeIndex");
    this.activeIndex = index;

    // 读取用户信息并判断角色
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user[0]) {
      const username = user[0].username;
      // 判断用户是否是 admin 或 root 只有root或者商务局显示所有数据页面
      this.isAdminOrRoot = username === 'root' || username === 'shangwuju';

      // 除了5个门店其他都能看到线上房展会核销 可点击页面
      const allowedUsers2 = ['root','gulou', 'xingan', 'wenhua', 'jinsheng', 'yuanhe', 'zhongshan', 'jinniu', 'minzu', 'tushu', 'snzhongshan','sngulou','shangwuju'];
      this.isAdminOrDar = allowedUsers2.includes(username);

      // 判断用户是否是 xingan 或 wanzheng 只有这些显示教师节活动
      // this.isAdminOrUser = username === 'xingan' || username === 'wanzheng';
      // 创建一个允许访问的用户名数组
      const allowedUsers = ['root','gulou', 'xingan', 'wenhua', 'jinsheng', 'yuanhe', 'haoqinying', 'wanzheng', 'jinyu', 'wendu', 'zhaojun'];
      // 使用 includes 判断用户名是否在数组中
      this.isAdminOrUser = allowedUsers.includes(username);



    }
  },
};
</script>


<style>
/* 一级菜单hover */
.el-submenu__title:focus,
.el-submenu__title:hover {
  outline: 0;
  color: #fff;
  background-color: transparent !important;
}

.style-a {
  text-decoration: none;
}
.is-active {
  color: #fff !important;
  background-color: #2d8cf0 !important;
}

.el-submenu__title {
  color: #bbbbbb;
}
</style>

<style lang="scss" scoped>
// el-header
.navbar {
  display: flex;
  justify-content: space-between;
}

.navbar-left {
  margin-right: 20px;
}

.avatar-wrapper {
  display: inline-block;
  width: 40px;
  height: 40px;
  vertical-align: middle;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-wrapper img {
  width: 100%;
  height: 100%;
}

.el-menu {
  /* //去掉菜单栏右边多出一像素的线条 */
  border-right: none;
}

.page {
  // border: 1px solid red;
}

.el-header {
  background-color: #fff !important;
  box-shadow: 0 1px 4px #00152914;
  color: #333639;
  transition: all 0.2s ease-in-out;
  z-index: 11;
}

.el-header,
.el-footer {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 60px;
}

.el-aside {
  background-color: rgb(0, 20, 40);
  color: #333;
  line-height: 200px;
}

.el-submenu {
  background-color: rgb(0, 20, 40);
}

.el-menu-item-group {
  background-color: rgb(0, 20, 40);
}

.el-main {
  background-color: #fff;
}
body > .el-container {
  margin-bottom: 40px;
}
</style>
