<template>
  <div class="ai-model">
    <el-container>
      <el-header>
        <h1>AI 大模型问答系统</h1>
      </el-header>
      <el-main>
        <el-input 
          v-model="question" 
          placeholder="来说点什么吧..." 
          :clearable="true"
          style="margin-bottom: 20px;"
        />
        <!-- <textarea 
          v-model="question" 
          @keydown="handleKeydown"
          placeholder="来说点什么吧...(Shift+Enter=换行)"
          style="width: 100%; height: 100px; margin-bottom: 20px;"
        ></textarea> -->
        <!-- <el-button type="primary" @click="askQuestion">提交</el-button>
          -->
          <el-button 
          type="primary" 
          :disabled="!question" 
          @click="askQuestion">
          提交
        </el-button>
        <el-divider></el-divider>
        <el-card v-if="response">
          <!-- <h3>回答:</h3> -->
          <p>{{ response }}</p>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import axios from 'axios'; // 导入 axios

export default {
  data() {
    return {
      question: '', // 用户输入的问题
      response: ''  // AI 模型返回的回答
    };
  },
  methods: {
    async askQuestion() {
      try {
        const res = await axios.post('http://localhost:3000/api/query', {
          question: this.question
        });
        // 提取模型返回的具体内容
        this.response = res.data.result; 
        console.log(res,"返回内容")
      } catch (error) {
        console.error('请求失败:', error);
        this.response = '请求失败，请检查控制台。';
      }
    },
    handleKeydown(event) {
      // 检查是否按下 Shift + Enter 键
      if (event.shiftKey && event.key === 'Enter') {
        event.preventDefault(); // 防止默认事件
        this.question += '\n'; // 添加换行符
      }
    }
  }
};
</script>

<style>
.ai-model {
  text-align: center;
  padding: 30px;
}
textarea {
  resize: none; /* 禁止调整大小 */
}
</style>
