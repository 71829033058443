<template>
  <div class="register-container">
    <el-form ref="registerForm" :model="registerForm" :rules="registerRules" class="register-form" label-position="left">
      <!-- 标题区域 -->
      <div class="title-container">
        <h3 class="title">东鸽门店用户注册</h3>
      </div>

      <!-- 用户名输入 -->
      <el-form-item label="用户名" prop="username">
        <el-input v-model="registerForm.username" placeholder="请输入用户名"></el-input>
      </el-form-item>

      <!-- 密码输入 -->
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="registerForm.password" placeholder="请输入密码"></el-input>
      </el-form-item>

      <!-- 注册代码输入 -->
      <el-form-item label="注册代码" prop="registrationCode">
        <el-input v-model="registerForm.registrationCode" placeholder="请输入注册代码"></el-input>
      </el-form-item>

      <!-- 提交按钮 -->
      <el-button type="primary" @click="handleRegister" style="width: 100%;">申请注册</el-button>
    </el-form>
  </div>
</template>

<script>
import { registerUser } from '../api/user';

export default {
  name: 'Register',
  data() {
    return {
      registerForm: {
        username: '',
        password: '',
        status:'',
        registrationCode: ''  // 新增注册代码字段
      },
      registerRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ],
        registrationCode: [  // 新增注册代码的验证规则
          { required: true, message: '请输入注册代码', trigger: 'blur' },
          { validator: (rule, value, callback) => {
              if (value !== '036913') {  // 验证注册代码是否正确
                callback(new Error('注册代码错误'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    handleRegister() {
      this.$refs.registerForm.validate(valid => {
        if (valid) {
          // 确保注册代码正确后再进行注册
          const data = {
            username: this.registerForm.username,
            password: this.registerForm.password,
            status:0,
            // 可以发送注册代码到服务器做进一步验证，安全性更强
            registrationCode: this.registerForm.registrationCode  
          };

          registerUser(data)
            .then(res => {
              this.$message.success('注册成功！');
              console.log('注册成功', res);
              // 注册成功后的逻辑，例如跳转到登录页
              // this.$router.push({ path: '/' });
            })
            .catch(err => {
              console.error('注册失败', err.response || err);
              const errorMessage = err.response?.data?.msg || '注册失败，请检查信息是否正确或联系信息中心！';
              this.$message.error(errorMessage);
            });
        } else {
          console.log('表单验证未通过');
          this.$message.error('表单信息填写不正确，请根据提示修改！');
        }
      });
    }
  }
};
</script>

<style scoped>
.register-container {
  width: 400px;
  margin: 50px auto;
  background: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
}

.title-container {
  text-align: center;
  margin-bottom: 20px;
}

.title {
  color: #333;
}
</style>

