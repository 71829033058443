import axios from '../../axios'

// 用户注册
export function registerUser(data) {
  return axios.post('/register', data);
}

// 用户登录
export function userLogin(data) {
  return axios.post(`/login`, data)
}

// 修改密码
export function updatePassword(data) {
  return axios.post('/updatePassword', data)
}

// 获取用户信息
export function getUserInfo(username){
  return axios.get('/getUserInfo?username=' + username)
}

// 新建文章
export function articles(data) {
  return axios.post(`/articles`, data)
}

// 获取文章列表
export function getArtList(data) {
  return axios.get(`/getArtList`, data)
}

// 删除文章
export function deleteArticle(id) {
  return axios.post('/deleteArticle?id=' + id)
}

// 修改文章
export function updateArticle(data) {
  return axios.post('/updateArticle', data)
}

// 获取欧派任务中心 订单下单类型 任务列表
export function getTaskList(data) {
  return axios.get(`/getTaskList`, data)
}

// 删除订单任务
export function deleteTask(id) {
  return axios.post('/deleteTask?id=' + id)
}

// 任务中心订单导入上传
export function uploadAndSortExcel(data) {
  return axios.post(`/uploadAndSortExcel`, data)
}

// 获取任务中心订单导入后的最新列表
export function getSortExcel(data) {
  return axios.get(`/getSortExcel`, data)
}

// 审核排队订单
export function auditOrder(data) {
  return axios.post(`/auditOrder`, data)
}

// 欧派第三方数据

// export function getRolePageList(data) {
//   return axios.get(`/getRolePageList`, data)
// }


// 分页请求用 params参数  商场管理角色列表
export function getRolePageList(params) {
  return axios.get(`/getRolePageList`, {params})
}

// 全部客户列表
export function getCustomerList(params) {
  return axios.get(`/getCustomerList`, {params})
}

// 客户列表数据数据库
export function getCustomerList2(params) {
  return axios.get(`/getCustomerList2`, {params})
}

// 东鸽集团 门店使用  报名客户
// 报名客户列表
export function getRegList(params) {
  return axios.get(`/getRegList`, {params})
}

// 修改领取状态
export function redeemCustomerGift(data) {
  return axios.post(`/redeemCustomerGift`, data);
}

// 6/21赛罕活动

export function getRegListSaiHan(params) {
  return axios.get(`/getRegListSaiHan`, {params})
}

// 修改领取状态
export function redeemCustomerGiftSaiHan(data) {
  return axios.post(`/redeemCustomerGiftSaiHan`, data);
}

// 8.31房展会活动

export function getRegListFangZhan(params) {
  return axios.get(`/getRegListFangZhan`, {params})
}

// 修改核销状态
export function redeemCustomerGiftFangZhan(data) {
  return axios.post(`/redeemCustomerGiftFangZhan`, data);
}

// 查看所有 特殊账号
export function getRegListFangZhanAll(params) {
  return axios.get(`/getRegListFangZhanAll`, {params})
}

// 第二批房展会活动 2024 12/24


export function getRegListFangZhan2(params) {
  return axios.get(`/getRegListFangZhan2`, {params})
}

// 修改核销状态
export function redeemCustomerGiftFangZhan2(data) {
  return axios.post(`/redeemCustomerGiftFangZhan2`, data);
}

// 查看所有 特殊账号
export function getRegListFangZhanAll2(params) {
  return axios.get(`/getRegListFangZhanAll2`, {params})
}



// 9/15教师节领取洗衣机活动

export function getRegListTeach(params) {
  return axios.get(`/getRegListTeach`, {params})
}
// 修改领取状态
export function redeemCustomerTeach(data) {
  return axios.post(`/redeemCustomerTeach`, data);
}

