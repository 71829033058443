const axios = require('axios');
const { Loading, Message } = require('element-ui');

const service = axios.create({
  // 本地测试环境使用下方
  // baseURL: 'http://localhost:3000',
  // 正式环境使用下方  http://62.234.192.148:3000
  baseURL: 'http://62.234.192.148:3000',
  timeout: 100000 
});

// 全局请求 loading封装
let loadingInstance = {
  instance: null,
  text: "数据加载中..."
}; 

service.interceptors.request.use((config) => {
  loadingInstance.instance = Loading.service({
    text: loadingInstance.text,
    background: 'rgba(0, 0, 0, 0.7)'
  });
  return config;
}, (error) => {
  return Promise.reject(error);
});

service.interceptors.response.use((response) => {
  loadingInstance.instance.close();
  return response;
}, (error) => {
  loadingInstance.instance.close();

  // 自定义错误处理
  let errorMessage = '请求失败，请稍后重试'; // 默认错误信息

  if (error.code === 'ERR_NETWORK' && error.message.includes('timeout')) {
    errorMessage = '请求超时，请检查您的网络连接';
  } else if (error.response) { // 确保有响应
    switch (error.response.status) {
      case 400:
        errorMessage = '账号或密码错误，请检查后重新输入';
        break;
      case 403:
        errorMessage = '当前账户已停用，请联系管理员';
        break;
      case 500:
        errorMessage = '服务器错误，请稍后重试';
        break;
      default:
        errorMessage = error.response.data.msg || '请求失败，请稍后重试';
    }
  }

  Message.error(errorMessage); // 使用 Element UI 的 Message 显示错误信息
  return Promise.reject(error);
});

module.exports = service;
